<template>
  <standard-layout :condition="subscription !== null && subscription !== undefined">
    <template v-slot:heading>
      <h1>Deine Benachrichtigungen</h1>
    </template>
    <template v-slot:main>
      <div class="card">
        <div class="card-body" v-if="!subscription.active">
          Eine Benachrichtigung für die Email-Adresse {{ subscription.email }} für den Zeitraum
          {{ subscription.fromDate | date }}-{{ subscription.toDate | date }} wurde gelöscht.
        </div>
        <div class="card-body" v-else>
          <div class="list-group">
            <div class="list-group-item">
              Email: {{ subscription.email }}
            </div>
            <div class="list-group-item">
              Zeitraum: {{ subscription.fromDate | date }}-{{ subscription.toDate | date }}
            </div>
            <div class="list-group-item">
              Mindestlänge: {{ subscription.minDuration }} Stunden
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-danger" @click="deleteSubscription">Suchanfrage löschen</button>
          </div>
        </div>
      </div>
      <ContactUs class="mt-4"/>
    </template>
  </standard-layout>
</template>

<script>
import ContactUs from "@/components/ContactUs";

export default {
  components: {ContactUs},
  props: {
    subscriptionId: String
  },
  data() {
    return {
      subscription: undefined
    }
  },
  methods: {
    deleteSubscription() {
      this.$api.delete(`/subscriptions/${this.subscriptionId}`).then(() => {
        this.subscription.active = false
      }).catch((err) => {
        this.handleError(err, true)
        this.showError("Die Einstellungen zu dieser Benachrichtigung konnten nicht geladen werden.")
        this.$router.push("/")
      })
    }
  },
  activated() {
    const method = this.$route.query.delete ? "DELETE" : "GET"
    this.$api.request({
      url: `/subscriptions/${this.subscriptionId}`,
      method
    }).then((response) => {
      if (method === 'GET')
        this.subscription = response.data
      else
        this.subscription.active = false
    }).catch((err) => {
      this.handleError(err, true)
      this.showError("Die Einstellungen zu dieser Benachrichtigung konnten nicht geladen werden.")
      this.$router.push("/")
    })
  }
}
</script>
